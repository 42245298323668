import React, {useState} from "react";
import {NavLink, Route} from "react-router-dom";
import {
  useProfile,
  isProfileRoleTypeTags,
} from "@vokymlak/kabinet-ui";
import {
  ROLE_ADMINISTRATOR,
  ROLE_CLIENT,
  ROLE_DIRECTOR,
  ROLE_EMPLOYEE,
} from "../../config/constants";

function MenuRouting(props) {
  const { profile } = useProfile();
  const {setIsModalProfile} = props;

  const onChange = () => {
    if (!!props.onChange) {
      props.onChange()
    }
  }

  return (
    <>
      {isProfileRoleTypeTags(profile, [
        ROLE_CLIENT
      ]) && (
        <ul className="Menu__list Menu__list_box">
          <li className="List__link">
            <NavLink className={"Link__text"} to={"/my/top"} onClick={() => onChange()}>
              Топ ниш
            </NavLink>
          </li>
          <li className="List__link">
            <NavLink className={"Link__text"} to={"/my/products"} onClick={() => onChange()}>
              Коллекция товаров
            </NavLink>
          </li>
          <li className="List__link">
            <NavLink className={"Link__text"} to={"/my/calculations"} onClick={() => onChange()}>
              Расчёт по категориям
            </NavLink>
          </li>
        </ul>
      )}

      {isProfileRoleTypeTags(profile, [
        ROLE_CLIENT
      ]) && (
        <ul className="Menu__list Menu__list_box">
          <li className="List__link">
            <NavLink className={"Link__text"} to={"/my/tariff"} onClick={() => onChange()}>
              Тарифный план
            </NavLink>
          </li>
        </ul>
      )}

      {/*{isProfileRoleTypeTags(profile, [*/}
      {/*  ROLE_CLIENT*/}
      {/*]) && (*/}
      {/*  <ul className="Menu__list Menu__list_box">*/}
      {/*    <li className="List__link">*/}
      {/*      <a className={"Link__text"} onClick={() => (onChange(), setIsModalProfile(true))}>*/}
      {/*        Аккаунт*/}
      {/*      </a>*/}
      {/*    </li>*/}
      {/*  </ul>*/}
      {/*)}*/}

      {/*{isProfileRoleTypeTags(profile, [*/}
      {/*  ROLE_ADMINISTRATOR,*/}
      {/*  ROLE_DIRECTOR,*/}
      {/*  ROLE_MANAGER,*/}
      {/*  // ROLE_CLIENT*/}
      {/*]) && (*/}
      {/*  <ul className="Menu__list Menu__list_box">*/}
      {/*    <li className="List__link">*/}
      {/*      <NavLink className={"Link__text"} to={"/my/integration"} onClick={() => onChange()}>*/}
      {/*        Интеграция с расширением*/}
      {/*      </NavLink>*/}
      {/*    </li>*/}
      {/*  </ul>*/}
      {/*)}*/}


      {/*{isProfileRoleTypeTags(profile, [*/}
      {/*  ROLE_ADMINISTRATOR,*/}
      {/*  ROLE_DIRECTOR,*/}
      {/*  ROLE_MANAGER,*/}
      {/*]) && (*/}
      {/*  <ul className="Menu__list Menu__list_box">*/}
      {/*    <li className="List__link">*/}
      {/*      <NavLink className={"Link__text"} to={"/product-calculations"} onClick={() => onChange()}>*/}
      {/*        Все расчеты*/}
      {/*      </NavLink>*/}
      {/*    </li>*/}
      {/*  </ul>*/}
      {/*)}*/}

      {isProfileRoleTypeTags(profile, [
        ROLE_ADMINISTRATOR,
        ROLE_DIRECTOR,
        ROLE_EMPLOYEE,
      ]) && (
        <ul className="Menu__list Menu__list_box">
          <li className="List__header">
            <div className="Header__text">Сайт</div>
          </li>
          <li className="List__link" style={{paddingTop: 12}}>
            <NavLink
              className={"Link__text"}
              to={"/site-callbacks"}
              onClick={() => onChange()}
            >
              Заявки с сайта
            </NavLink>
          </li>
        </ul>
      )}

      {isProfileRoleTypeTags(profile, [
        ROLE_ADMINISTRATOR,
        ROLE_DIRECTOR,
      ]) && (
        <ul className="Menu__list Menu__list_box">
          <li className="List__link">
            <NavLink className={"Link__text"} to={"/tariffs-selling"} onClick={() => onChange()}>
              Оформление тарифов
            </NavLink>
          </li>
          <li className="List__link">
            <NavLink className={"Link__text"} to={"/tariffs-setting"} onClick={() => onChange()}>
              Настройка тарифов
            </NavLink>
          </li>
          <li className="List__link">
            <NavLink className={"Link__text"} to={"/tariffs-codes"} onClick={() => onChange()}>
              Промо-коды для тарифов
            </NavLink>
          </li>
        </ul>
      )}

      {isProfileRoleTypeTags(profile, [
        ROLE_EMPLOYEE,
      ]) && (
        <ul className="Menu__list Menu__list_box">
          <li className="List__link">
            <NavLink className={"Link__text"} to={"/tariffs-selling"} onClick={() => onChange()}>
              Оформление тарифов
            </NavLink>
          </li>
        </ul>
      )}

      {isProfileRoleTypeTags(profile, [
        ROLE_ADMINISTRATOR,
        ROLE_DIRECTOR,
        ROLE_EMPLOYEE,
      ]) && (
        <ul className="Menu__list Menu__list_box">
          <li className="List__link">
            <NavLink className={"Link__text"} to={"/clients"} onClick={() => onChange()}>
              Клиенты
            </NavLink>
          </li>
          <li className="List__link">
            <NavLink className={"Link__text"} to={"/employees"} onClick={() => onChange()}>
              Сотрудники
            </NavLink>
          </li>
          {isProfileRoleTypeTags(profile, [ROLE_ADMINISTRATOR]) && (
            <li className="List__link">
              <NavLink className={"Link__text"} to={"/accounts"} onClick={() => onChange()}>
                Аккаунты
              </NavLink>
            </li>
          )}
        </ul>
      )}

      {isProfileRoleTypeTags(profile, [
        ROLE_ADMINISTRATOR,
        ROLE_DIRECTOR,
      ]) && (
        <ul className="Menu__list Menu__list_box">
          <li className="List__header">
            <div className="Header__text">Данные</div>
          </li>
          <li className="List__link" style={{
            paddingTop: 12
          }}>
            <NavLink className={"Link__text"} to={"/info-exchange-rates"} onClick={() => onChange()}>
              Курс обмена
            </NavLink>
          </li>
          <li className="List__link">
            <NavLink className={"Link__text"} to={"/wb-commission"} onClick={() => onChange()}>
              WB комиссия
            </NavLink>
          </li>
          <li className="List__link">
            <NavLink className={"Link__text"} to={"/wb-delivery"} onClick={() => onChange()}>
              WB коэффициенты складов
            </NavLink>
          </li>
          <li className="List__link">
            <NavLink className={"Link__text"} to={"/wb-return-cost"} onClick={() => onChange()}>
              WB стоимость возврата товара продавцу
            </NavLink>
          </li>
          <li className="List__link">
            <NavLink className={"Link__text"} to={"/wb-availability"} onClick={() => onChange()}>
              WB оборачиваемость
            </NavLink>
          </li>

          <li className="List__link">
            <NavLink className={"Link__text"} to={"/mpstats-subjects"} onClick={() => onChange()}>
              MPSTATS данные
            </NavLink>
          </li>
          <li className="List__link">
            <NavLink className={"Link__text"} to={"/wb-products"} onClick={() => onChange()}>
              WB товары
            </NavLink>
          </li>
        </ul>
      )}

      {isProfileRoleTypeTags(profile, [
        ROLE_ADMINISTRATOR,
        ROLE_DIRECTOR,
      ]) && (
        <ul className="Menu__list Menu__list_box">
          <li className="List__header">
            <div className="Header__text">Парсинг</div>
          </li>
          <li className="List__link" style={{
            paddingTop: 12
          }}>
            <NavLink className={"Link__text"} to={"/parser-wb"} onClick={() => onChange()}>
              Парсинг WB
            </NavLink>
          </li>

          <li className="List__link">
            <NavLink className={"Link__text"} to={"/parser-taobao"} onClick={() => onChange()}>
              Парсинг 1688
            </NavLink>
          </li>
        </ul>
      )}

      {isProfileRoleTypeTags(profile, [
        ROLE_ADMINISTRATOR,
        ROLE_DIRECTOR,
      ]) && (
        <ul className="Menu__list Menu__list_box">
          <li className="List__link">
            <NavLink className={"Link__text"} to={"/handbook"} onClick={() => onChange()}>
              Справочник
            </NavLink>
          </li>
        </ul>
      )}

      {isProfileRoleTypeTags(profile, [
        ROLE_ADMINISTRATOR,
        ROLE_DIRECTOR,
      ]) && (
        <ul className="Menu__list Menu__list_box">
          <li className="List__link">
            <NavLink className={"Link__text"} to={"/tickets"} onClick={() => onChange()}>
              Тикеты
              <div style={{
                fontSize: 12,
                opacity: .75
              }}>
                Задачи по улучшениям и ошибкам
              </div>
            </NavLink>
          </li>
        </ul>
      )}
    </>
  );
}

export default MenuRouting;
