import React, {Fragment} from "react";
import {NavLink, useNavigate} from "react-router-dom";
import "./TicketsMenu.scss";
import {ScrollBlock} from "../../../../components/ScrollBlock/ScrollBlock";
import {ROOT_URL} from "../../../../config/url";
import {ButtonIcon, useAction, useObject} from "@vokymlak/kabinet-ui";

export const TicketsMenu = () => {
  const navigate = useNavigate();

  const {
    object: [object],
    update: [_isUpdateObject, _setIsUpdateObject, updateObject],
  } = useObject({apiUrl: "/api/ticket-topics/view", rootUrl: ROOT_URL});

  useAction(["tickets"], () => updateObject());

  const getTopicCount = (object, uuid) => {
    let count = null;
    if (!!object && !!object.ticket_topics) {
      object.ticket_topics.forEach(t => {
        if (t.uuid === uuid) {
          count = t.count_tickets;
        }
      })
    }
    return count;
  }

  function isOnlyDashes(str) {
    return /^-+$/.test(str);
  }

  return (
    <ScrollBlock style={{width: "100%", height: "100%"}}>
      <div className="TicketsMenu">
        <div className="Menu__header">
          <div className="Header__text">
            Тикеты
          </div>

          <ButtonIcon
            tooltipText={"Настройки"}
            iconName={"custom-settings"}
            iconColor={"#20202033"}
            size={24}
            onClick={() => navigate("/tickets/topics")}
          />
        </div>

        <ul className="Menu__list" style={{paddingBottom: 0}}>
          <li className="List__link">
            <NavLink
              className="Link__text"
              to={"/tickets/all"}
            >
              Все
              {!!object && !!object.count_all_tickets && (
                <span style={{
                  marginLeft: 4,
                  opacity: .75,
                  fontSize: 12
                }}>
                    ({object.count_all_tickets})
                  </span>
              )}

              {!!object && !object.count_all_tickets && (
                <span style={{
                  marginLeft: 4,
                  opacity: .75,
                  fontSize: 12
                }}>
                    (0)
                  </span>
              )}
            </NavLink>
          </li>
          <li className="List__link">
            <NavLink
              className="Link__text"
              to={"/tickets/no-topic"}
            >
              Без темы
              {!!object && !!object.count_no_ticket_topics && (
                <span style={{
                  marginLeft: 4,
                  opacity: .75,
                  fontSize: 12
                }}>
                    ({object.count_no_ticket_topics})
                  </span>
              )}

              {!!object && !object.count_no_ticket_topics && (
                <span style={{
                  marginLeft: 4,
                  opacity: .75,
                  fontSize: 12
                }}>
                    (0)
                  </span>
              )}
            </NavLink>
          </li>
        </ul>

        <ul className="Menu__list" style={{paddingBottom: 0}}>
          <li className="List__link">
            <NavLink
              className="Link__text"
              to={"/tickets/author"}
            >
              Мои тикеты
              {!!object && !!object.count_author_tickets && (
                <span style={{
                  marginLeft: 4,
                  opacity: .75,
                  fontSize: 12
                }}>
                    ({object.count_author_tickets})
                  </span>
              )}

              {!!object && !object.count_author_tickets && (
                <span style={{
                  marginLeft: 4,
                  opacity: .75,
                  fontSize: 12
                }}>
                    (0)
                  </span>
              )}
            </NavLink>
          </li>
          <li className="List__link">
            <NavLink
              className="Link__text"
              to={"/tickets/executor"}
            >
              Я исполнитель
              {!!object && !!object.count_executor_tickets && (
                <span style={{
                  marginLeft: 4,
                  opacity: .75,
                  fontSize: 12
                }}>
                    ({object.count_executor_tickets})
                  </span>
              )}

              {!!object && !object.count_executor_tickets && (
                <span style={{
                  marginLeft: 4,
                  opacity: .75,
                  fontSize: 12
                }}>
                    (0)
                  </span>
              )}
            </NavLink>
          </li>
        </ul>

        {/*<ul className="Menu__list">*/}
        {/*  <li className="List__link">*/}
        {/*    <NavLink*/}
        {/*      className="Link__catalog"*/}
        {/*      to={"/tickets/tracker"}*/}
        {/*    >*/}
        {/*      Трекер*/}
        {/*    </NavLink>*/}
        {/*  </li>*/}
        {/*</ul>*/}

        {!!object && (
          <ul className="Menu__list" style={{paddingBottom: 0}}>
            <div
              className={"List__title"}
              style={{opacity: .75, cursor: 'default', fontSize: 16}}
            >
              По темам

            </div>
            {!!object.ticket_topics && object.ticket_topics.map((item) => (
              <Fragment key={item.uuid}>
                <li className="List__link">
                  {isOnlyDashes(item.title) && (
                    <div
                     style={{
                       marginTop: 12,
                       marginBottom: 12,
                       backgroundColor: "#20202015",
                       height: 1,
                       width: "95%"
                     }}
                    >
                    </div>
                  )}
                  {!isOnlyDashes(item.title) && (
                  <NavLink
                    className="Link__text"
                    to={"/tickets/topic/" + item.uuid}
                  >
                    {item.title}
                    {!!getTopicCount(object, item.uuid) && (
                      <span style={{
                        marginLeft: 4,
                        opacity: .75,
                        fontSize: 12
                      }}>
                    ({getTopicCount(object, item.uuid)})
                  </span>
                    )}

                    {!getTopicCount(object, item.uuid) && (
                      <span style={{
                        marginLeft: 4,
                        opacity: .75,
                        fontSize: 12
                      }}>
                    (0)
                  </span>
                    )}
                  </NavLink>
                  )}
                </li>
              </Fragment>
            ))}
          </ul>
        )}

        <ul className="Menu__list" style={{paddingBottom: 0}}>
          <li className="List__link">
            <NavLink
              className="Link__text"
              to={"/tickets/archive"}
            >
              В архиве
              {!!object && !!object.count_archive_tickets && (
                <span style={{
                  marginLeft: 4,
                  opacity: .75,
                  fontSize: 12
                }}>
                    ({object.count_archive_tickets})
                  </span>
              )}

              {!!object && !object.count_archive_tickets && (
                <span style={{
                  marginLeft: 4,
                  opacity: .75,
                  fontSize: 12
                }}>
                    (0)
                  </span>
              )}
            </NavLink>
          </li>
        </ul>

        <ul className="Menu__list" style={{paddingBottom: 0}}>
          <li className="List__link">
            <NavLink
              className="Link__text"
              to={"/tickets/time-tracker"}
            >
              Трекер
            </NavLink>
          </li>
        </ul>

      </div>
    </ScrollBlock>
  );
};
